"use client";

import { Row, Col, Input, Space, Button, message } from 'antd/lib';
import { useState } from 'react';
import { useAccount } from 'wagmi';
import SendArrowIcon from '../../public/home/send-arrow-icon.svg';
import LinkedinIcon from '../../public/common/linkedin.svg';
import TwitterIcon from '../../public/common/twitter.svg';
import DiscordIcon from '../../public/common/discord.svg';

import Link from 'next/link';

import './footer.styles.scss';

export default function Footer() {

   const [newsletterEmail, setNewsletterEmail] = useState('');
   const { address, isConnected } = useAccount();

   const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewsletterEmail(e.target.value);
   };

   const handleSubscribe = async () => {
      if (!newsletterEmail) {
         message.error('Please enter a valid email address');
         return;
      }

      if (!isConnected || !address) {
         message.error('Please connect your wallet to subscribe.');
         return;
      }

      try {
         const response = await fetch('/api/newsletter', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({ email: newsletterEmail, walletAddress: address }),
         });

         if (response.ok) {
         message.success('You have successfully subscribed to the newsletter!');
         } else {
         const errorData = await response.json();
         message.error(`Subscription failed: ${errorData.error}`);
         }
      } catch (error) {
         console.error('Failed to subscribe:', error);
         message.error('An error occurred while subscribing. Please try again.');
      }
   };

  return (
    <div className="footer-container">
      <div  className="footer-container-overlay"></div>
      <Row className="footer-row">
        <Col
          xs={{ span: 10 }}
          md={{ span: 10 }}
          sm={{ span: 10 }}
          lg={{ span: 10 }}
          className="ant-col-footer-newsletter" 
        >
          <div className='footer-newsletter-wrapper'></div>
          <h1 className='footer-newsletter-title'>
            Sign up to our newsletter and stay in the know!
          </h1>
          <Space direction="vertical" size="middle" className="footer-newsletter-btn">
            <Space.Compact style={{ width: '100%' }}>
              <Input
                placeholder="Enter your email to join our mission updates"
                value={newsletterEmail}
                onChange={handleEmailChange}
              />
              <Button
                type="primary"
                className="footer-newsletter-send"
                onClick={handleSubscribe}
              >
                <SendArrowIcon />
              </Button>
            </Space.Compact>
          </Space>
        </Col>    
        <Col
          xs={{ span: 14 }}
          md={{ span: 14 }}
          sm={{ span: 14 }}
          lg={{ span: 14 }}
          className="ant-col-footer-sitemap" 
        >
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-title" 
            >
              Company
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-title" 
            >
              Ecosystem
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-title" 
            >
              Community
            </Col>

            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-link"  
            >
              <Link href="/about" className="footer-sitemap-link-text">
                Team
              </Link>
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-link"   
            >
              <Link href="/concept" className="footer-sitemap-link-text">
                Concept
              </Link>
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-link"  
            >
              <Link href="/about" className="footer-sitemap-link-text">
                Discord
              </Link>
            </Col>

            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-link"  
            >
              <Link href="/roadmap" className="footer-sitemap-link-text">
                Roadmap
              </Link>
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-link"   
            >
              <Link href="/projects" className="footer-sitemap-link-text">
                Projects
              </Link>
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-link"  
            >
              <Link href="/about" className="footer-sitemap-link-text">
                LinkedIn
              </Link>
            </Col>

            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-link"   
            >
              <Link href="/concept" className="footer-sitemap-link-text">
                Concept
              </Link>
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-link"   
            >
              <Link href="/register" className="footer-sitemap-link-text">
                Become Greg
              </Link>
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              className="footer-sitemap-link"  
            >
              <Link href="/about" className="footer-sitemap-link-text">
                Blog
              </Link>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="footer-row-media">
            <Col span={8} className="footer-col-media">
              <div className="ball">
                <span className="shadow"></span>
                <LinkedinIcon className="media-icon" />
              </div>
            </Col>
            <Col span={8} className="footer-col-media">
              <div className="ball">
                <span className="shadow"></span>
                <TwitterIcon className="media-icon" />
              </div>
            </Col>
            <Col span={8} className="footer-col-media">
              <div className="ball">
                <span className="shadow"></span>
                <DiscordIcon className="media-icon" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}