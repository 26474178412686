import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserAuthProps } from '@/types/types';

interface UserAuthState {
  userAuth: (Omit<UserAuthProps, 'connectionTimestamp' | 'userAuthCreatedAt'> & {
    connectionTimestamp: string; 
    userAuthCreatedAt: string;
  }) | null;
}

const initialState: UserAuthState = {
  userAuth: null,
};

const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setUserAuth(state, action: PayloadAction<UserAuthState['userAuth']>) {
      state.userAuth = action.payload;
    },
    clearUserAuth(state) {
      state.userAuth = null;
    },
  },
});

export const { setUserAuth, clearUserAuth } = userAuthSlice.actions;

export default userAuthSlice.reducer;
