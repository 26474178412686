import { configureStore } from '@reduxjs/toolkit';
import userAuthReducer from './features/userAuth/userAuthSlice';
import userProjectReducer from './features/userProject/userProjectSlice';

const store = configureStore({
  reducer: {
    userAuth: userAuthReducer,
    userProject: userProjectReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
