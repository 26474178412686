"use client";

import { Row, Col } from 'antd/lib';
import LandingWave from '../../../public/home/landing-waves.svg';
import AnimTest from '../../../public/home/anim.svg';

import './landing.styles.scss';

export default function Landing() {
  
  return (
    <div className="landing-container">
      <Row className="landing-row">
         <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 24 }}
            className="ant-col-title"  
         >
            <h1 className="landing-title">
               Building the world greatest renewable energy community
            </h1>
            <h2 className="landing-subtitle">
               A global community of individuals reimagining renewable energy production and ownership
            </h2>
         </Col>
        </Row>

      <LandingWave className="landing-waves-svg" />
    </div>
  );
}