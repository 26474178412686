import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProjectProps } from '@/types/types';

interface UserProjectState {
  projects: UserProjectProps[];
}

const initialState: UserProjectState = {
  projects: [],
};

const userProjectSlice = createSlice({
  name: 'userProject',
  initialState,
  reducers: {
    addProject(state, action: PayloadAction<UserProjectProps>) {
      state.projects.push(action.payload);
    },

    updateProject(state, action: PayloadAction<{ buyerPurchaseId: string; updatedProject: Partial<UserProjectProps> }>) {
      const { buyerPurchaseId, updatedProject } = action.payload;
      const projectIndex = state.projects.findIndex((project) => project.buyerPurchaseId === buyerPurchaseId);

      if (projectIndex !== -1) {
        state.projects[projectIndex] = { ...state.projects[projectIndex], ...updatedProject };
      }
    },

    removeProject(state, action: PayloadAction<string>) {
      state.projects = state.projects.filter((project) => project.buyerPurchaseId !== action.payload);
    },

    clearProject(state) {
      state.projects = [];
    },
  },
});

export const { addProject, updateProject, removeProject, clearProject } = userProjectSlice.actions;

export default userProjectSlice.reducer